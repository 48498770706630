import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

interface ConfirmModalProps {
  closeModal: () => any;
  confirm: () => any;
  isOpen: boolean;
  text: string | React.ReactNode;
}

const ConfirmModal: React.SFC<ConfirmModalProps> = ({
  closeModal,
  confirm,
  isOpen,
  text,
}) => (
  <Modal isOpen={isOpen} toggle={closeModal}>
    <ModalHeader>Confirmation</ModalHeader>
    <ModalBody>{text}</ModalBody>
    <ModalFooter>
      <Button
        color="success"
        onClick={() => {
          confirm();
          closeModal();
        }}
      >
        Confirm
      </Button>
      <Button onClick={closeModal}>Cancel</Button>
    </ModalFooter>
  </Modal>
);

export default ConfirmModal;
