import gql from 'graphql-tag';
import { AnalyticsFragment } from '@/graphql/fragments';

export default gql`
  query analyticsUrlQuery(
    $dashboardCode: String!
    $networkID: String!
    $domain: String! # $startDate: String! # $endDate: String!
  ) # $vendorID: String
  # $layerID: String
  # $mpVendorCampaignName: String
  # $managerID: String
  {
    analyticsUrl(
      dashboardCode: $dashboardCode
      networkID: $networkID
      domain: $domain # startDate: $startDate # endDate: $endDate
    ) # vendorID: $vendorID
    # layerID: $layerID
    # mpVendorCampaignName: $mpVendorCampaignName
    # managerID: $managerID
    {
      ...AnalyticsFragment
    }
  }
  ${AnalyticsFragment}
`;
