import React from 'react';
import Switch from 'react-switch';
import { FaSync } from 'react-icons/fa';
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
} from 'reactstrap';
import { Form } from '@availity/form';
import { Vars, formatCurrencyForDisplay } from '@/utils';
import * as yup from 'yup';
import { useMutation } from '@apollo/react-hooks';
import { updateUserProfile } from '@/graphql/mutations';
import { useNotifications } from '@/hooks';
import { MoneyField } from '@/components';
import useProfile from '../../useProfile';

import VsoftConnection from './Components/VsoftConnection';

interface ConnectionsProps {
  // amount: number;
  // enabled: boolean;
  // threshold: number;
  viewLoading: boolean;
  agentID: string;
}

const Connections: React.FunctionComponent<ConnectionsProps> = ({
  // amount,
  // enabled,
  // threshold,
  viewLoading,
  agentID,
}) => {
  const { data: user } = useProfile();

  const { create: createNotification } = useNotifications();

  // const [
  //   updateProfile,
  //   { loading: updating },
  //   // eslint-disable-next-line react-hooks/rules-of-hooks
  // ] = useMutation(updateUserProfile, {
  //   update(cache, { data: { accountUpdate } }) {
  //     const { userCurrent, account } = cache.readQuery({
  //       query: integrationVsoftQuery,
  //     }) as any;

  //     cache.writeQuery({
  //       query: currentAgentQuery,
  //       data: {
  //         account,
  //         userCurrent: {
  //           ...userCurrent,
  //           callerReadyAccountStatus: accountUpdate.callerReadyAccountStatus,
  //         },
  //       },
  //     });
  //   },
  //   onCompleted: () => {
  //     createNotification({
  //       color: 'success',
  //       type: 'alert',
  //       action: 'MESSAGE',
  //       message: `Service's settings saved.`,
  //     });
  //   },
  //   onError: () =>
  //     createNotification({
  //       color: 'danger',
  //       type: 'alert',
  //       action: 'MESSAGE',
  //       message: `Failed to Service's settings. Please try again later.`,
  //     }),
  // });

  return (
    <Form
      initialValues={{
        callerReadyAccountStatus: user.callerReadyAccountStatus || 'none',
        callerReadyStatus: user.callerReadyStatus || 'active',
      }}
      validationSchema={yup.object().shape({
        callerReadyAccountStatus: yup.string(),
        callerReadyStatus: yup.string(),
      })}
      onSubmit={(values) => {
        // updateProfile({
        //   variables: {
        //     input: {
        //       agentID,
        //       callerReadyAccountStatus: values.callerReadyAccountStatus,
        //       callerReadyStatus: user.callerReadyStatus || 'active',
        //     },
        //   },
        // });
      }}
    >
      {({ setFieldValue, values, handleReset }) => (
        <>
          <Card className="mt-2">
            <CardHeader className="d-flex justify-content-between">
              <CardTitle
                tag="h5"
                className="py-2 w-100 h4 mb-0 d-flex justify-content-between"
              >
                <span>Connections</span>
              </CardTitle>
            </CardHeader>
            <CardBody>Connections allow your update your CRM directly</CardBody>
          </Card>
          <br />
          {/* {user.flags.allowVsoftConnections ? ( */}
          {/* <VsoftConnection
            setFieldValue={setFieldValue}
            values={values}
            user={user}
          /> */}
          {/* ) : null} */}
        </>
      )}
    </Form>
  );
};

export default Connections;
